<template>
    <div class="lubanStore">
        <div class="padd20 borbut20" style="position: relative;">
            <!-- <div class="financiallistFrome" :class="spredPackup ? 'active' : ''"> -->
                <div class="topSearchSou">
                    <div class="searchdan">
                        <div class="title">店铺ID：</div>
                        <el-input v-model="Storeid" placeholder="请输入店铺ID" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
                    </div>
                    <div class="searchdan">
                        <div class="title">店铺标题：</div>
                        <el-input v-model="Store_title" placeholder="请输入店铺标题"></el-input>
                    </div>
                    <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                    <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' >重置数据</el-button>
                </div>
            <!-- </div>
            <div class="RightBotton">
                
            </div>
            <div class="spred" @click="onSpred">{{spredPackup ? '收起' : '展开更多筛选'}} <i :class="spredPackup ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" /></div> -->
        </div>
        <div class="group-table padd20">
            <div class="duoshao">
                <div class="title">鲁班店铺</div>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="ErStoreList" style="width: 100%">
                <el-table-column prop="id" label="店铺编号"></el-table-column>
                <el-table-column prop="account_port" label="店铺图片">
                     <template slot-scope="scope">
                         <el-tooltip placement="left">
                            <div slot="content"><img :src="scope.row.logo" style="width:300px;" alt=""></div>
                            <img :src="scope.row.logo" style="width:50px;height:50px" alt="">
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="店铺标题" width="250"></el-table-column>
                <el-table-column prop="of_company" label="店铺公司" width="250"></el-table-column>
                <el-table-column prop="shop_num" label="店铺商品数量"></el-table-column>
                <el-table-column prop="dates" label="更新时间">
                     <template slot-scope="scope">
                         {{dateFtt("yyyy-MM-dd",new Date(scope.row.updated_at))}}
                    </template>
                </el-table-column>
                <el-table-column prop="today_volume" label="昨日销量"></el-table-column>
                <el-table-column prop="three_sales" label="三日销量"></el-table-column>
                <el-table-column prop="seven_sales" label="七日销量"></el-table-column>
                <el-table-column prop="sales_volume" label="总销量"></el-table-column>
            </el-table>
            <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            paramData:{},//表格参数
            // spredPackup:false,//展开搜索
            Storeid:"",//店铺id
            Store_title:"",//店铺标题
            online_time:"",//上线时间
            pickerTime:null,//开始日期结束日期
            product_code: "", //店铺code
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            ErStoreList:[],//店铺列表
        };
    },

    mounted() {
        this.ongetErStoreList()
    },

    methods: {
        //展开更多搜索条件
        // onSpred(){
        //     this.spredPackup = !this.spredPackup
        // },
        //日期选择触发
        onPicker(){
        },
        //店铺列表
        ongetErStoreList(){
            this.ErStoreList = []
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getErStoreList,param, (res)=> {
                if(res.code == '200'){
                    this.ErStoreList = res.data.data
                    this.lvtotal = res.data.total;
                }
            })
        },
        //店铺搜索
        onButtonInquire(){
            this.paramData = {
                id:this.Storeid,//店铺id
                name:this.Store_title,//店铺标题
                // online_time:this.online_time ? this.dateFtt("yyyy-MM-dd", this.online_time) : "",//上线时间
                // start_created_at: this.pickerTime ? this.pickerTime[0] : "", //开始日期
                // end_created_at: this.pickerTime ? this.pickerTime[1] : "", //结束日期
                // product_code: this.product_code, //店铺code
            }
            this.paramPage = 1
            this.ongetErStoreList()
        },
         // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetErStoreList()
        },
    },
};
</script>

<style lang="scss" scoped>
.lubanStore{
    .topSearchSou{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .searchdan:first-child{
            margin-left: 20px;
        }
    }
}
</style>
<style lang="scss">
</style>